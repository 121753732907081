import React from "react";
import { Box, Container, Typography, keyframes } from "@mui/material";

const textAnimation = keyframes`
  0% { opacity: 0; transform: translateY(30px); }
  100% { opacity: 1; transform: translateY(0); }
`;

// Define local font using @font-face
const localFontImport = `
  @font-face {
    font-family: 'YourCustomFont';
    src: url('/assets/fonts/YourCustomFont.woff2') format('woff2'),
         url('/assets/fonts/YourCustomFont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;

const TopHomePage: React.FC = () => {
  return (
    <>
      {/* Inject local font CSS */}
      <style>{localFontImport}</style>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          backgroundImage:
            "linear-gradient( rgba(255, 243, 249, 0.8), rgba(255, 243, 249, 0.8)),url(/assets/images/bgccakebar.jpg)",
          backgroundSize: "cover",
          height: "90vh",
          textAlign: "center",
          pt: "20vh",
        }}
      >
        {/* Chocolate Drip */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "550px",
            background: `url(/assets/images/chocolate1.png) no-repeat center top`,
            backgroundSize: "cover",
            zIndex: 1,
          }}
        />
        <Container
          maxWidth="md"
          sx={{ position: "relative", zIndex: 2, mt: 3 }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: "3rem", md: "5rem" },
              fontWeight: "bold",
              color: "transparent",

              background: "linear-gradient(90deg, #ee9ca7, #b5816b)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              animation: `${textAnimation} 1.5s ease-out`,
              mb: 2,
            }}
          >
            {/* Apply the local font */}
            <span
              style={{
                fontSize: "1.2em",
                color: "#ee9ca7",
                WebkitTextStroke: "0.6px black",
                fontFamily: "'YourCustomFont', sans-serif", // Using local font
              }}
            >
              בר{" "}
            </span>
            <span
              style={{
                fontSize: "1em",
                color: "#b5816b",
                WebkitTextStroke: "0.6px black",
                fontFamily: "'YourCustomFont', sans-serif", // Using local font
              }}
            >
              מתוקים
            </span>
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "1.2rem", md: "2rem" },
              color: "#ee9ca7",
              textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
              animation: `${textAnimation} 2s ease-out`,
              mb: 4,
              WebkitTextStroke: "0.6px black",
            }}
          >
            עוגות בהתאמה אישית שיוצרות רגעים מתוקים
          </Typography>
        </Container>{" "}
      </Box>{" "}
    </>
  );
};

export default TopHomePage;
